$themes: (
  dark: (
    textColor: white,
    textLogo: #258ba5,
    textSoft: #7C98A9,
    textLight: #A3B7C3,
    bg: #131A22,
    bgSoft: #2c3c4c,
    textColorSoft: lightgray,
    border: #444,
    button: #1c1c1c,
    buttonText: white
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}


@mixin sm-mobile {
  @media (max-width: 380px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (min-width: 576px) and (max-width: 767.98px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 761px) and (max-width: 991.98px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 991.98px) and (max-width: 1199.98px) {
    @content;
  }
}
@mixin large-desktop {
  @media (min-width: 1199.98px) {
    @content;
  }
}