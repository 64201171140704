@import "../../style.scss";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100;200;300;400;500;600;700;800&display=swap');

.customnavbar {
  @include themify($themes) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 10px 30px;
    background-color: themed("bg");
    border: none;
    color: themed("textColor");
    position: sticky;
    top: 0;
    z-index: 10;
    font-family: 'IBM Plex Mono', monospace;

    @include mobile-landscape{
      padding: 10px 30px;
    }

    .logo {
      display: flex;
      align-items: center;
      gap: 30px;

      img {
        width: 40px;
        height: 40px;
      }
      
    }

    .hamburger{
      display: flex;
      align-items: center;
      font-size: 40px;
      transform: transform 1s;

      @include tablet {
        display: none;
      }
      @include desktop {
        display: none;
      }
      @include large-desktop {
        display: none;
      }
      
    }

    .nav-bar {

      @include mobile{
        height: 0px;
        position: absolute;
        top: 70px;
        left: 0px;
        right: 0px;
        width: 100vw;
        background-color: themed("bg");
        transition: 0.5s;
        overflow: hidden;
      }
      @include mobile-landscape{
        height: 0px;
        position: absolute;
        top: 70px;
        left: 0px;
        right: 0px;
        width: 100vw;
        background-color: themed("bg");
        transition: 0.5s;
        overflow: hidden;
      }


      ul {
        display: flex;
        align-items: center;
        margin: 0px;

        @include mobile{
          display: block;
          width: fit-content;
          margin: 80px auto 0px auto;
          text-align: center;
          transition: 0.5s;
          padding: 0px;
          opacity: 0;
        }
        @include mobile-landscape{
          display: block;
          width: fit-content;
          margin: 80px auto 0px auto;
          text-align: center;
          transition: 0.5s;
          padding: 0px;
          opacity: 0;
        }
        
        li {
          display: block;
          a {
            display: block;
            text-decoration: none;
            color: themed("textColor");
            padding: 10px 25px;
            transition: 0.2s;
            margin: 0px 5px;

            @include mobile{
              margin-bottom: 12px;
            }
            @include mobile-landscape{
              margin-bottom: 12px;
            }

            .navmenu{
              color: themed('textColor');
              font-size: 13px;
              font-weight: 100;
              cursor: pointer;

              .menuindex{
                color: #5C9DC0;
                font-family: 'Martian Mono', monospace;
              }
              .menuname{
                font-family: 'Martian Mono', monospace;
              }
            }
            .navmenu:hover{
              color: #5C9DC0;
            }
          }
        }
      }
    }

    .nav-bar.active{
      @include mobile{
        height: 350px;

        ul {
          opacity: 1;
        }
      }
      @include mobile-landscape{
        height: 350px;

        ul {
          opacity: 1;
        }
      }
    }

  }
}
