@import "../../style.scss";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100;200;300;400;500;600;700;800&display=swap");

.home {
  @include themify($themes) {
    display: flex;
    justify-content: center;
    .homecontainer {
      display: flex;
      flex-direction: column;
      background-color: themed("bg");
      height: calc(100vh - 70px);
      color: themed("textColor");
      padding: 50px 20px;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      // intro banner
      .intro1 {
        margin-bottom: 18px;
        span {
          font-size: 15px;
          font-family: "Martian Mono", monospace;
          font-weight: 400;
          color: #5c9dc0;
        }
      }
      .introName {
        span {
          font-size: 60px;
          font-weight: bold;
          color: themed("textColor");

          @include mobile {
            font-size: 40px;
          }
          @include mobile-landscape {
            font-size: 40px;
          }
          @include tablet {
            font-size: 50px;
          }
        }
      }
      .intro2 {
        margin-bottom: 20px;
        span {
          font-size: 60px;
          font-weight: bold;
          color: #a3b7c3;

          @include mobile {
            font-size: 40px;
          }
          @include mobile-landscape {
            font-size: 40px;
          }
          @include tablet {
            font-size: 50px;
          }
        }
      }
      .intro3 {
        margin-bottom: 150px;
        span {
          font-size: 17px;
          font-weight: 400;
          color: #a3b7c3;

          @include mobile {
            font-size: 15px;
          }
          @include mobile-landscape {
            font-size: 15px;
          }
          @include tablet {
            font-size: 15px;
          }
        }
      }

      // about section
      .sectionTitleContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 25px;

        .sectionTitleHolder {
          display: flex;
          align-items: center;
          justify-content: center;

          .titleCount {
            color: #5c9dc0;
            font-family: "Martian Mono", monospace;
            font-size: 25px;
            margin-right: 10px;

            @include mobile {
              font-size: 18px;
            }
            @include mobile-landscape {
              font-size: 18px;
            }
            @include tablet {
              font-size: 18px;
            }
          }
          .titleName {
            color: themed("textColor");
            font-weight: bold;
            font-family: "Martian Mono", monospace;
            font-size: 28px;
            margin-right: 15px;

            @include mobile {
              font-size: 19px;
            }
            @include mobile-landscape {
              font-size: 19px;
            }
            @include tablet {
              font-size: 20px;
            }
          }
          hr {
            border: none;
            border-bottom: 1px solid white;
            flex-grow: 1;
          }
        }
      }

      .aboutMeContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;
        margin-bottom: 150px;

        p {
          // font-family: 'Martian Mono', monospace;
          color: #a3b7c3;
          font-size: 17px;
          font-weight: 400;

          @include mobile {
            font-size: 15px;
          }
          @include mobile-landscape {
            font-size: 15px;
          }
          @include tablet {
            font-size: 15px;
          }
        }

        .techStackSection {
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;

          .stackHolder {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            .rightIcon {
              font-family: "Martian Mono", monospace;
              color: themed("textLogo");
              margin-right: 10px;
              font-size: 12px;

              @include mobile {
                font-size: 10px;
              }
              @include mobile-landscape {
                font-size: 10px;
              }
              @include tablet {
                font-size: 10px;
              }
            }
            .stackName {
              font-family: "Martian Mono", monospace;
              font-weight: 200;
              font-size: 12px;
              color: themed("textLight");

              @include mobile {
                font-size: 10px;
              }
              @include mobile-landscape {
                font-size: 10px;
              }
              @include tablet {
                font-size: 10px;
              }
            }
          }
        }

        // about me photo secttion
        .photoContainer {
          display: flex;
          justify-content: center;
          .photoHolder {
            top: 0px;
            height: 300px;
            width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            position: relative;
            transition: padding 500ms;

            @include mobile {
              height: 280px;
              width: 280px;
            }
            @include mobile-landscape {
              height: 280px;
              width: 280px;
            }
            @include tablet {
              height: 280px;
              width: 280px;
            }

            .photoFrame {
              width: 280px;
              height: 280px;
              border-radius: 5px;
              border: 1px solid white;

              @include mobile {
                height: 260px;
                width: 260px;
              }
              @include mobile-landscape {
                height: 260px;
                width: 260px;
              }

              @include tablet {
                height: 260px;
                width: 260px;
              }
            }
            .imgContainer {
              width: 280px;
              height: 280px;
              border-radius: 5px;
              overflow: hidden;
              position: absolute;
              left: 0px;
              top: 0px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;

              @include mobile {
                height: 260px;
                width: 260px;
              }
              @include mobile-landscape {
                height: 260px;
                width: 260px;
              }
              @include tablet {
                height: 260px;
                width: 260px;
              }
              .effectlayer {
                width: 280px;
                height: 280px;
                // background-color: themed("textLogo");
                // opacity: 0.2;

                @include mobile {
                  height: 260px;
                  width: 260px;
                }
                @include mobile-landscape {
                  height: 260px;
                  width: 260px;
                }

                @include tablet {
                  height: 260px;
                  width: 260px;
                }
              }
            }
          }

          .photoHolder:hover {
            padding: 5px;
            .imgContainer {
              .effectlayer {
                opacity: 0;
              }
            }
          }
        }
      }

      .socialIcons{
        display: none;
        justify-content: center;
        align-items: center;
        gap: 40px;
        margin-bottom: 20px;

        @include mobile {
          display: flex;
        }

        @include mobile-landscape{
          display: flex;
        }

        a{
          text-decoration: none;
          color: white;
          font-size: 20px;
        } 

      }

      .footer {
        font-family: "Martian Mono", monospace;
        font-size: 10px;
        font-weight: 100;
        color: white;
        text-align: center;
        // margin-top: 100px;
      }
    }
  }
}
