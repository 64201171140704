.layout {
    /* custom flex width */
    .flex_md_30 {
      width: 33.33%;
    }
    .flex_md_37 {
      width: 37.5%;
    }
    .flex_md_25 {
      width: 25%;
    }
    .flex_md_50 {
      width: 50%;
    }
    .flex_md_60{
      width: 60%;
    }
    .flex_md_70 {
      width: 70%;
    }
    .flex_md_75 {
      width: 75%;
    }
    .flex_md_80 {
      width: 80%;
    }
  }
  
  @media (max-width: 380px) {
    /* custom flex width */
    .flex_md_30 {
      width: 100%;
    }
    .flex_md_37 {
      width: 100%;
    }
    .flex_md_25 {
      width: 100%;
    }
    .flex_md_50 {
      width: 100%;
    }
    .flex_md_60{
      width: 100%;
    }
    .flex_md_70 {
      width: 100%;
    }
    .flex_md_75 {
      width: 100%;
    }
    .flex_md_80 {
      width: 100%;
    }
  }
  @media (max-width: 575.98px) {
    /* custom flex width */
    .flex_md_30 {
      width: 100%;
    }
    .flex_md_37 {
      width: 100%;
    }
    .flex_md_25 {
      width: 100%;
    }
    .flex_md_50 {
      width: 100%;
    }
    .flex_md_60{
      width: 100%;
    }
    .flex_md_70 {
      width: 100%;
    }
    .flex_md_75 {
      width: 100%;
    }
    .flex_md_80 {
      width: 100%;
    }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    /* custom flex width */
    .flex_md_30 {
      width: 50%;
    }
    .flex_md_37 {
      width: 50%;
    }
    .flex_md_25 {
      width: 50%;
    }
    .flex_md_50 {
      width: 100%;
    }
    .flex_md_60{
      width: 100%;
    }
    .flex_md_70 {
      width: 100%;
    }
    .flex_md_75 {
      width: 100%;
    }
    .flex_md_80 {
      width: 100%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    /* custom flex width */
    .flex_md_30 {
      width: 50%;
    }
    .flex_md_37 {
      width: 50%;
    }
    .flex_md_25 {
      width: 33.33%;
    }
    .flex_md_50 {
      width: 50%;
    }
    .flex_md_60{
      width: 60%;
    }
    .flex_md_70 {
      width: 70%;
    }
    .flex_md_75 {
      width: 75%;
    }
    .flex_md_80 {
      width: 100%;
    }
  }
  
  @media (min-width: 991.98px) and (max-width: 1199.98px) {
    /* custom flex width */
    .flex_md_30 {
      width: 33.33%;
    }
    .flex_md_37 {
      width: 37.5%;
    }
    .flex_md_25 {
      width: 25%;
    }
    .flex_md_50 {
      width: 50%;
    }
    .flex_md_60{
      width: 60%;
    }
    .flex_md_70 {
      width: 70%;
    }
    .flex_md_75 {
      width: 75%;
    }
    .flex_md_80 {
      width: 80%;
    }
  }
  
  @media (min-width: 1199.98px) {
    /* custom flex width */
    .flex_md_30 {
      width: 33.33%;
    }
    .flex_md_37 {
      width: 37.5%;
    }
    .flex_md_25 {
      width: 25%;
    }
    .flex_md_50 {
      width: 50%;
    }
    .flex_md_60{
      width: 60%;
    }
    .flex_md_70 {
      width: 70%;
    }
    .flex_md_75 {
      width: 75%;
    }
    .flex_md_80 {
      width: 80%;
    }
  }
  