@import "../../style.scss";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100;200;300;400;500;600;700;800&display=swap");

.experience {
  margin-bottom: 150px;
  @include themify($themes) {
    display: flex;
    .workmenu {
      display: flex;
      flex-direction: column;
      padding-right: 5px;

      .menuholder {
        height: 50px;
        display: flex;
        align-items: center;
        border: none;
        border-left: 2px solid themed("bgSoft");
        padding-left: 15px;
        color: themed("textLight");

        @include mobile {
          span {
            font-size: 15px;
          }
        }
        @include mobile-landscape {
          span {
            font-size: 15px;
          }
        }
        @include tablet {
          span {
            font-size: 15px;
          }
        }
      }
      .menuactive {
        border-left: 2px solid themed("textLogo");
      }
      .menuholder:hover {
        cursor: pointer;
        background-color: themed("bgSoft");
        color: themed("textLogo");
      }
    }
    .workDetailContainer {
      padding-left: 20px;
      .detailHolder {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .roleDetail {
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 15px;
          .title {
            // display: flex;
            // gap: 10px;
            .role {
              font-size: 18px;
              font-weight: 400;
              color: themed("textColor");
              margin-right: 10px;

              @include mobile {
                font-size: 15px;
              }
              @include mobile-landscape {
                font-size: 15px;
              }
              @include tablet {
                font-size: 15px;
              }
            }
            .place {
              font-size: 18px;
              font-weight: 400;
              color: #5c9dc0;

              @include mobile {
                font-size: 15px;
              }
              @include mobile-landscape {
                font-size: 15px;
              }
              @include tablet {
                font-size: 15px;
              }
            }
          }
          .timeline {
            span {
              font-size: 14px;
              color: themed("textLight");

              @include mobile {
                font-size: 10px;
              }
              @include mobile-landscape {
                font-size: 10px;
              }
              @include tablet {
                font-size: 10px;
              }
            }
          }
        }

        .taskDetailHolder {
          display: flex;
          margin-bottom: 10px;
          .iconHolder {
            flex: 1;
            font-family: "Martian Mono", monospace;
            color: themed("textLogo");
            font-size: 12px;
            padding-top: 3px;

            @include mobile {
              font-size: 10px;
            }
            @include mobile-landscape {
              font-size: 10px;
            }
            @include tablet {
              font-size: 10px;
            }
          }
          .taskDetail {
            flex: 11;
            color: themed("textLight");
            font-size: 17px;

            @include mobile {
              font-size: 15px;
            }
            @include mobile-landscape {
              font-size: 15px;
            }
            @include tablet {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
