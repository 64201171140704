@import "../../style.scss";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap');

.customLeftbar{
    @include themify($themes){
        width: 80px;
        position: sticky;
        top: 70px;
        height: calc(100vh - 70px);
        overflow: scroll;
        color: themed('textColor');

        &::-webkit-scrollbar {
            display: none;
          }
      
          @include mobile {
            display: none;
          }
        
          .itemContainer{
            height: calc(100vh - 70px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;
            bottom: 0px;
            a {
                text-decoration: none;
                color: themed('textColor');
                font-size: 20px;
            }
            .dividerLine{
                display: flex;
                flex-direction: row;
                .bleft{
                    border: none;
                    border-right: 1px solid themed('textColor');
                    height: 100px;
                }
                .bright{
                    border: none;
                    // border-left: 1px solid white;
                    height: 100px;
                }
            }
            
          }
    }
}