@import "../../style.scss";
@import "../../app.scss";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100;200;300;400;500;600;700;800&display=swap");

.worksection {
  margin-bottom: 130px;
  @include themify($themes) {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .sectionContainer {
      display: flex;
      flex-wrap: wrap;

      .detailSection {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .projectType {
          display: flex;
          margin-bottom: 10px;
          span {
            font-family: "Martian Mono", monospace;
            font-size: 10px;
            color: #5c9dc0;
          }
        }

        .projectTypeRA{
          display: flex;
          justify-content: flex-end;
          margin-bottom: 10px;

          @include mobile {
            justify-content: flex-start;
          }
          @include mobile-landscape {
            justify-content: flex-start;
          }
          span {
            font-family: "Martian Mono", monospace;
            font-size: 10px;
            color: #5c9dc0;
          }
        }

        .projectName {
          display: flex;
          margin-bottom: 30px;
          opacity: 1;

          @include mobile {
            margin-bottom: 10px;
          }
          @include mobile-landscape {
            margin-bottom: 10px;
          }

          span {
            color: themed("textColor");
            font-weight: bold;
            font-family: "Martian Mono", monospace;
            font-size: 28px;
            opacity: 1;

            @include mobile {
              font-size: 19px;
            }
            @include mobile-landscape {
              font-size: 19px;
            }
            @include tablet {
              font-size: 20px;
            }
          }
        }
        .projectNameRA {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 30px;
          opacity: 1;

          @include mobile {
            margin-bottom: 10px;
            justify-content: flex-start;
          }
          @include mobile-landscape {
            margin-bottom: 10px;
            justify-content: flex-start;
          }
          span {
            color: themed("textColor");
            font-weight: bold;
            font-family: "Martian Mono", monospace;
            font-size: 28px;
            opacity: 1;

            @include mobile {
              font-size: 19px;
            }
            @include mobile-landscape {
              font-size: 19px;
            }
            @include tablet {
              font-size: 20px;
            }
          }
        }

        .mobileViewImageContainer{
          display: none;
          border-radius: 5px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            // background-color: themed('bgSoft');
            // border-bottom: 1px solid themed('bgSoft');
            // border-right: 1px solid themed('bgSoft');
            box-shadow: 3px 3px 2px themed('bgSoft');
            margin-bottom: 20px;

            @include mobile {
              display: block;
            }
            @include mobile-landscape {
              display: block;
            }
  
            .mobileViewImageholder {
              border-radius: 10px;
              width: 100%;
              img{
                width: 100%;
                object-position: center;
                object-fit: cover;
                border-radius: 5px;
              }
            }
        }

        .projDescHolder {
          display: flex;
          margin-bottom: 10px;
          .iconHolder {
            flex: 1;
            font-family: "Martian Mono", monospace;
            color: themed("textLogo");
            font-size: 12px;
            padding-top: 3px;
            

            @include mobile {
              font-size: 10px;
            }
            @include mobile-landscape {
              font-size: 10px;
            }
            @include tablet {
              font-size: 10px;
            }
          }
          .iconHolderRA {
            flex: 1;
            font-family: "Martian Mono", monospace;
            color: themed("textLogo");
            font-size: 12px;
            padding-top: 5px;
            display: flex;
            justify-content: flex-end;

            @include mobile {
              display:none;
            }
            @include mobile-landscape {
              display:none;
            }
            @include tablet {
              font-size: 10px;
            }
          }
          .iconHolderMobile {
            flex: 1;
            font-family: "Martian Mono", monospace;
            color: themed("textLogo");
            font-size: 12px;
            padding-top: 3px;
            display: none;

            @include mobile {
              font-size: 10px;
              display: block;
            }
            @include mobile-landscape {
              font-size: 10px;
              display: block;
            }
            
          }

          .taskDetail {
            flex: 11;
            color: themed("textLight");
            font-size: 17px;

            @include mobile {
              font-size: 15px;
            }
            @include mobile-landscape {
              font-size: 15px;
            }
            @include tablet {
              font-size: 15px;
            }
          }
          .taskDetailRA {
            flex: 11;
            color: themed("textLight");
            font-size: 17px;
            text-align: end;

            @include mobile {
              font-size: 15px;
              text-align: start;
            }
            @include mobile-landscape {
              font-size: 15px;
              text-align: start;
            }
            @include tablet {
              font-size: 15px;
            }
          }
        }
      }
      .imageContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        
        .imagewrapper{
          border-radius: 5px;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;
          padding-bottom: 20px;
          // background-color: themed('bgSoft');
          // border-bottom: 1px solid themed('bgSoft');
          // border-right: 1px solid themed('bgSoft');
          box-shadow: 5px 5px 6px themed('bgSoft');

          .imageholder {
            border-radius: 10px;
            width: 100%;
            img{
              width: 100%;
              object-position: center;
              object-fit: cover;
              border-radius: 5px;
            }
          }
        }

        @include mobile {
          display: none;
        }
        @include mobile-landscape {
          display: none;
        }
        
        
      }
    }
  }
}
