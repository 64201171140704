@import "../../style.scss";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100;200;300;400;500;600;700;800&display=swap");

.contactme {
  @include themify($themes) {
    .sectiontitle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      span {
        color: themed("textColor");
        font-weight: bold;
        font-family: "Martian Mono", monospace;
        font-size: 40px;

        @include mobile{
            font-size: 35px;
        }
      }
    }

    .sectionDescHolder{
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        .sectiondesc {
            font-size: 17px;
            font-weight: 400;
            color: #a3b7c3;
            text-align: center;
      
            @include mobile {
              font-size: 15px;
            }
            @include mobile-landscape {
              font-size: 15px;
            }
            @include tablet {
              font-size: 15px;
            }
          }
    }

    .mailBtnContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;

        .mailBtn{
            padding: 10px 15px 10px 15px;
            border-radius: 5px;
            border: 1px solid #5c9dc0;
            font-family: "Martian Mono", monospace;
            font-size: 15px;
            font-weight: 100;
            color: #5c9dc0;
            cursor: pointer;
        }
        .mailBtn:hover{
            background-color: themed('bgSoft');
        }
    }
    
  }
}
